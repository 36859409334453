const conditionalRadios = document.querySelectorAll(".js-conditional-radio");

/**
 * Handle conditional rendering of a div if a radio choice is selected.
 *
 * use data-condition to customize the value needed to show the target.
 * defaults to 'true'
 */
Array.from(conditionalRadios).forEach(function (wrapper) {
  const conditional = wrapper.dataset.condition ?? "true";

  const radio = wrapper.querySelectorAll(
    ".js-conditional-radio input[type=radio]"
  );
  const target = wrapper.querySelector(
    ".js-conditional-radio .js-conditional-radio-target"
  );

  radio.forEach(function (input) {
    if (input.checked === true && input.value === conditional) {
      target.style.display = "block";
    }
    input.addEventListener("change", function (e) {
      if (e.target.value === conditional) {
        target.style.display = "block";
      } else {
        target.style.display = "none";
      }
    });
  });
});

const conditionalCheckbox = document.querySelectorAll(
  ".js-conditional-checkbox"
);

Array.from(conditionalCheckbox).forEach(function (wrapper) {
  const checkbox = wrapper.querySelectorAll(
    ".js-conditional-checkbox input[type=checkbox]"
  );
  const target = wrapper.querySelector(
    ".js-conditional-checkbox .js-conditional-checkbox-target"
  );

  checkbox.forEach(function (input) {
    if (input.checked === true) {
      target.style.display = "block";
    }
    input.addEventListener("change", function (e) {
      if (e.target.checked === true) {
        target.style.display = "block";
      } else {
        target.style.display = "none";
      }
    });
  });
});
