// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LocalTime from "local-time";
import { debounce } from "../src/shared/Debounce";
import Alpine from "alpinejs";
import { define } from "remount";
import { validateComponentProps } from "../src/validateComponentProps";
import { SocialShare } from "../src/SocialShare";
require("@rails/ujs").start();
require("@rails/activestorage").start();
// require('channels');

import { MapboxMap, Props as MapboxMapProps } from "../src/MapboxMap";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
require.context("../images", true);

require("../stylesheets/application.scss");

document.addEventListener("DOMContentLoaded", function (event) {
  require("../src/forms");
});

window.Alpine = Alpine;
Alpine.start();

LocalTime.start();

Bugsnag.start({
  apiKey: window.AppConfig.BUGSNAG_FRONTEND_API_KEY || "invalid",
  plugins: [new BugsnagPluginReact()],
  releaseStage: window.AppConfig.RAILS_ENV,
  enabledReleaseStages: ["production", "staging"],
});

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight;
  debounce(document.documentElement.style.setProperty("--vh", `${vh}px`), 100);
});

document.addEventListener("DOMContentLoaded", () => {
  define({
    "mapbox-map": validateComponentProps(MapboxMapProps.decode, MapboxMap),
    "social-share": SocialShare,
  });
});

import "@hotwired/turbo-rails";
Turbo.session.drive = false;

document.addEventListener("turbo:frame-load", (event) => {
  if (typeof window.Trustpilot == "undefined") {
    return;
  }

  event.target.querySelectorAll(".trustpilot-widget").forEach((widget) => {
    window.Trustpilot.loadFromElement(widget);
  });
});
