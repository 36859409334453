import * as React from "react";
import { IconLink } from "@tabler/icons-react";
import { IconTwitter } from "./Icons/IconTwitter";
import { IconFacebook } from "./Icons/IconFacebook";
import { IconLinkedIn } from "./Icons/IconLinkedIn";
import classNames from "classnames";

interface Props {
  url: string;
  shareText: string;
  orientation?: "vertical" | "horizontal";
}

export const SocialShare: React.FC<Props> = ({
  url,
  shareText,
  orientation = "horizontal",
}) => {
  const [showCopiedAlert, setShowCopiedAlert] = React.useState(false);

  const twitterParams = new URLSearchParams({ url, text: shareText });
  const twitterUrl = `https://twitter.com/intent/tweet?${twitterParams}`;

  const facebookParams = new URLSearchParams({ u: url });
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?${facebookParams}`;

  const linkedInParams = new URLSearchParams({ url });
  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?${linkedInParams}`;

  const linkClass =
    "grid h-11 w-11 place-items-center rounded-full border border-neutral-300 hover:bg-neutral-100 transition-colors bg-white";

  const share = () => {
    const shareData = {
      url,
      title: shareText,
      text: shareText,
    } satisfies ShareData;
    if (
      typeof navigator.share === "function" &&
      navigator.canShare(shareData)
    ) {
      navigator.share(shareData).catch(() => {});
    } else {
      navigator.clipboard.writeText(url);
      setShowCopiedAlert(true);
      window.setTimeout(() => {
        setShowCopiedAlert(false);
      }, 1000);
    }
  };

  return (
    <div
      className={classNames(
        "relative flex gap-4",
        orientation === "vertical" ? "flex-col" : null
      )}
    >
      <button className={linkClass} onClick={share}>
        <IconLink aria-hidden="true" className="h-5 w-5 stroke-current" />
        <span className="sr-only">Share page</span>
      </button>

      <a
        href={twitterUrl}
        target="_blank"
        className={linkClass}
        rel="noopener noreferrer"
      >
        <IconTwitter aria-hidden="true" className="h-5 w-5 fill-current" />
        <span className="sr-only">Share on Twitter</span>
      </a>
      <a
        href={facebookUrl}
        target="_blank"
        className={linkClass}
        rel="noopener noreferrer"
      >
        <IconFacebook aria-hidden="true" className="h-5 w-5 fill-current" />
        <span className="sr-only">Share on Facebook</span>
      </a>
      <a
        href={linkedInUrl}
        target="_blank"
        className={linkClass}
        rel="noopener noreferrer"
      >
        <IconLinkedIn aria-hidden="true" className="h-5 w-5 fill-current" />
        <span className="sr-only">Share on LinkedIn</span>
      </a>
      {showCopiedAlert ? (
        <div className="absolute -bottom-8 left-0 py-2 text-sm">
          Link copied
        </div>
      ) : null}
    </div>
  );
};
