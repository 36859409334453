import * as React from "react";
import * as t from "io-ts";
import { Map, MapMarker } from "@scandotcom/react/dist/esm/components/Map";

const Marker = t.type({
  id: t.string,
  lat: t.number,
  lng: t.number,
  title: t.string,
});

type Marker = t.TypeOf<typeof Marker>;

export const Props = t.intersection([
  t.type({
    lat: t.number,
    lng: t.number,
  }),
  t.partial({
    markers: t.array(Marker),
    initialZoom: t.number,
  }),
]);

type Props = t.TypeOf<typeof Props>;

export const MapboxMap: React.FC<Props> = ({
  lat,
  lng,
  markers,
  initialZoom = 9,
}) => {
  return (
    <Map
      mapboxAccessToken={window.AppConfig.MAPBOX_ACCESS_TOKEN}
      scrollZoom={false}
      attributionControl={false}
      initialViewState={{ latitude: lat, longitude: lng, zoom: initialZoom }}
      className="relative h-full w-full"
    >
      {markers?.map((marker) => (
        <MapMarker
          style={{ color: "green" }}
          longitude={marker.lng}
          latitude={marker.lat}
          key={marker.id}
        />
      ))}
    </Map>
  );
};
