import { pipe } from "fp-ts/lib/function";
import type { Decode } from "io-ts";
import * as E from "fp-ts/Either";
import { formatValidationErrors } from "io-ts-reporters";
import React from "react";

/**
 * A higher-order component that verifies that the props passed to
 * it conform to the correct type, as specified by the `decode` function.
 *
 * If the props are invalid will render an error instead of trying to
 * render the component.
 *
 * Use to safely render components from ERB templates where props are
 * passed as raw JSON and type information is lost.
 *
 * @param decode An io-ts decoder that runtime checks the type of the props
 * @param Component The component to render with the props.
 * @returns
 */
export function validateComponentProps<P extends {}>(
  decode: Decode<unknown, P>,
  Component: React.ComponentType<P>
) {
  const PropsValidator = (props: unknown) =>
    pipe(
      props,
      decode,
      E.mapLeft(formatValidationErrors),
      E.fold(
        (errors) => (
          <div className="text-danger-600 text-sm">
            <p className="font-semibold">Invalid props passed to component:</p>
            <ul className="list-disc pl-3">
              {errors.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </div>
        ),
        (validProps) => <Component {...validProps} />
      )
    );
  return PropsValidator;
}
